import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Layout, Radio, Form, Input, Button, message, List, Tag } from 'antd';
import './App.css';
import io from 'socket.io-client';

const { Content, Footer } = Layout;

const socket = io('');
const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
	
};
const tailLayout = {
  wrapperCol: { offset: 6, span: 18 },
};

let infos = [];

const App = () => {
	const formRef = React.createRef();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false)
	const [resultData, setResultData] = useState([]);
	const formType = 'freshworks';
	useEffect(() => {
		socket.on('message', data=> {
			if(data.code === 0) {
				message.success(data.message);
			}
			if(data.code === 1) {
				console.log()
				infos.push({type: 'error', text: data.message});
				setResultData(infos);
				message.error(data.message);
			}
		})
		socket.on('completed', data => {
			infos.push({type: 'success', text: data.message});
			setLoading(false);
			message.success(data.message)
		})
		socket.on('warning', data => {
			infos.push({type: 'warning', text: data.message});
			setResultData(infos);
			message.warn(data.message)
		})
	}, [])
	const defaultValues = {
		freshworks: {
			sheetId: '',
			sheetTitle: ''
		},
		shopify: {
			sheetId: '',
			sheetTitle: ''
		}
	}
	const onSelectorChange =  (evt) => {
		const value = evt.target.value;
    formRef.current.setFieldsValue({
			sheetID: defaultValues[value].sheetId,
			sheetTitle: defaultValues[value].sheetTitle,
		});
  };
	const onSubmit = (values) => {
		infos = [];
		setLoading(true);
		setResultData(infos);
		axios({
			url: '/freshworksapi/sync-sheet',
			method: 'get',
			params: {...values}
		})
	}
  return (
    <Layout>
			<Content style={{
				minHeight: 'calc(100vh - 48px)',
				padding: '40px',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
			}}>
				<Form
					{...layout}
					form={form}
					initialValues={{
						formSelector: formType,
						sheetID: defaultValues[formType].sheetId,
						sheetTitle: defaultValues[formType].sheetTitle,
					}}
					ref={formRef}
					size="large"
					style={{width: '600px'}}
					onFinish={onSubmit}
				>
					<Form.Item
					 	name="formSelector"
						label="Form Selector" 
					>
						<Radio.Group onChange={onSelectorChange}>
							<Radio.Button value="freshworks">Freshworks Synchronize</Radio.Button>
							<Radio.Button value="shopify">Shopify Url Check</Radio.Button>
						</Radio.Group>
					</Form.Item>
					<Form.Item 
						name="sheetID" 
						label="Sheet ID" 
						rules={[{ required: true, message: 'Please input Sheet ID!' }]} 
					>
						<Input placeholder="input google sheet ID"/>
					</Form.Item>
					<Form.Item 
						name="sheetTitle" 
						label="Sheet Title"
						rules={[{ required: true, message: 'Please input Sheet Title!' }]} 
					>
						<Input placeholder="input sheet title"/>
					</Form.Item>
					<Form.Item {...tailLayout}>
						<Button type="primary" htmlType="submit" loading={loading}>Submit</Button>
					</Form.Item>
				</Form>
				{resultData.length !== 0 && <List
					style={{width: '600px'}}
					size="small"
					bordered
					dataSource={resultData}
					renderItem={item => <List.Item><Tag color={item.type}>{item.text}</Tag></List.Item>}
				/>}
			</Content>
			<Footer></Footer>
		</Layout>
  );
}

export default App;
